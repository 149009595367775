@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: 'HelveticaNeueLTPro-BlkEx';
    src: url(../assets/HelveticaNeueLTPro-BlkEx.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueLTPro-BlkExO';
    src: url(../assets/HelveticaNeueLTPro-BlkExO.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueLTPro-Ex';
    src: url(../assets/HelveticaNeueLTPro-Ex.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueLTPro-ExO';
    src: url(../assets/HelveticaNeueLTPro-ExO.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueLTPro-MdCn';
    src: url(../assets/HelveticaNeueLTPro-MdCn.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueLTPro-MdCnO';
    src: url(../assets/HelveticaNeueLTPro-MdCnO.otf) format('opentype');
}

html, body {
    overflow-x: hidden;
    /* Safari compatibility */
    height: 100%;
    width: 100%;
    transform: translate3d(0,0,0);
}

.dotted-red {
    background-image: url('../assets/ellipse.svg');
    background-size: 20px;
    background-repeat: space;
}

.text-red-outline {
    -webkit-text-stroke: 1px theme('colors.red');
    color: transparent;
}

.h-full-dots-top {
    height: calc(100% + 1rem);
}

.h-full-dots-bottom {
    height: calc(100% + 5rem);
}

.h-05 {
    height: 0.125rem;
}

.markup p ~ p {
    margin-top: 1rem;
}

.markup a {
    color: theme('colors.red');
    text-decoration: underline;
    text-underline-offset: 4px;
}

/* Ugly non-Tailwindy hack, I know, but fuck it. */
.underlined-links-parent a {
    text-decoration: underline;
}

@media only screen and (min-width: 769px) {
    .dotted-red {
        background-size: 28px;
    }

    .text-red-outline {
        -webkit-text-stroke: 2px theme('colors.red');
        color: transparent;
    }

    .h-full-dots-top {
        height: calc(100% + 15rem) !important;
    }

    .h-full-dots-bottom {
        height: calc(100% + 36rem) !important;
    }
}

@media only screen and (min-width: 1920px) {
    .w-hd-80 {
        width: 90%;
    }
}
